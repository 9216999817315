// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.authForm{
    background-color: #FFF;
    width: 100%;
    max-width: 464px;
    max-height: 342px;
    position: fixed;
    margin: auto;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    border-radius: 16px;
}

.formHeader{
    padding-top: 25px;
    padding-bottom: 45px;
}

.authForm .MuiInputBase-formControl{
    margin-bottom: 16px;
    width: 272px;
}

button{
    border-radius: 8px;
    text-transform: none!important;
}`, "",{"version":3,"sources":["webpack://./src/pages/login/Login.css"],"names":[],"mappings":"AAAA;IACI,sBAAsB;IACtB,WAAW;IACX,gBAAgB;IAChB,iBAAiB;IACjB,eAAe;IACf,YAAY;IACZ,OAAO;IACP,MAAM;IACN,QAAQ;IACR,SAAS;IACT,mBAAmB;AACvB;;AAEA;IACI,iBAAiB;IACjB,oBAAoB;AACxB;;AAEA;IACI,mBAAmB;IACnB,YAAY;AAChB;;AAEA;IACI,kBAAkB;IAClB,8BAA8B;AAClC","sourcesContent":[".authForm{\n    background-color: #FFF;\n    width: 100%;\n    max-width: 464px;\n    max-height: 342px;\n    position: fixed;\n    margin: auto;\n    left: 0;\n    top: 0;\n    right: 0;\n    bottom: 0;\n    border-radius: 16px;\n}\n\n.formHeader{\n    padding-top: 25px;\n    padding-bottom: 45px;\n}\n\n.authForm .MuiInputBase-formControl{\n    margin-bottom: 16px;\n    width: 272px;\n}\n\nbutton{\n    border-radius: 8px;\n    text-transform: none!important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
