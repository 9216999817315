import {configureStore} from '@reduxjs/toolkit';
import userReducer from '../store/userSlice';
import {saveState} from "../helpers/redux";

export const store = configureStore({
    reducer: {
        user: userReducer
    }
});

store.subscribe(() => {
    saveState(store.getState());
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;