import {createSlice} from "@reduxjs/toolkit";

const savedToken = localStorage.getItem('reduxState');
const savedRole = localStorage.getItem('reduxState');

const initialState = {
    access: savedToken ? savedToken.toString() : null,
    role: savedRole ? savedRole.toString() : null
};

const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        loginUser(state, action) {
            state.access = action.payload;
            state.role = action.payload;
        },
        logoutUser(state) {
            state.access = null;
            state.role = null;
        }
    }
})

export const {loginUser, logoutUser} = userSlice.actions;

export default userSlice.reducer;
