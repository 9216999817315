import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import "./Admin.css";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { logoutUser } from "../../store/userSlice";
import { getToken } from "../../store/selectors";
import { Endpoints } from "../../api/endpoints";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Container } from "@mui/material";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import Select from "@mui/material/Select";
import Modal from "@mui/material/Modal";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import Box from "@mui/material/Box";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { Button, TextField, FormControl, IconButton } from "@mui/material";
import dayjs, { Dayjs } from "dayjs";
import RenderExpandableCell from "./RenderExpandableCell";

const theme = createTheme({
  palette: {
    primary: {
      main: "#A02130",
    },
  },
});

interface IParam {
  name: string;
  param_value: [
    { id: number; name: string; value: string; head_param_value: number }
  ];
  id: number;
  head_param: number;
}

interface IHistory {
  user: string;
  time: Date;
  params: [{ name: string; value: string }];
}

interface ISelectedParams {
  param_id: Number;
  selected_value_id: Number;
}

interface IUser {
  id: Number;
  username: string;
  role: string;
  registration_date: Date;
  ban_date: Date;
}



const names = ["Промпт 1"];

const Admin = () => {
  let navigate = useNavigate();
  const { access } = useAppSelector(getToken);
  const dispatch = useAppDispatch();

  const [openAddParam, setOpenAddParam] = useState(false);
  const handleOpenAddParam = () => setOpenAddParam(true);
  const handleCloseAddParam = () => setOpenAddParam(false);

  const [openAddUser, setOpenAddUser] = useState(false);
  const handleOpenAddUser = () => setOpenAddUser(true);
  const handleCloseAddPUser = () => setOpenAddUser(false);

  const [openDeleteParam, setOpenDeleteParam] = useState(false);
  const handleOpenDeleteParam = () => setOpenDeleteParam(true);
  const handleCloseDeleteParam = () => setOpenDeleteParam(false);

  const handleLogOut = () => {
    dispatch(logoutUser());
    localStorage.clear();
    navigate("/login");
  };

  const [params, setParams] = useState<IParam[]>([]);
  const [staticParams, setStaticParams] = useState<IParam[]>([]);
  const [personName, setPersonName] = useState<string[]>([]);

  const [currentParamId, setCurrentParamId] = useState(0);
  const [paramName, setParamName] = useState("");
  const [paramValue, setParamValue] = useState("");
  const [currentParamValueId, setCurrentParamValueId] = useState(0);
  const [currentParamName, setCurrentParamName] = useState("");
  const [currentParamValue, setCurrentParamValue] = useState("");
  const [currentHeadParamValue, setCurrentHeadParamValue] = useState(0);
  const [promtText, setPromtText] = useState("");
  const [currentTab, setCurrentTab] = useState(0);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [editedStatus, setEditedStatus] = useState(false);
  const [history, setHistory] = useState<IHistory[]>([]);
  const [showHistory, setShowHistory] = useState(false);
  const [showUsers, setShowUsers] = useState(false);
  const [selectedParams, setSelectedParams] = useState<ISelectedParams[]>([]);
  const [users, setUsers] = useState<IUser[]>([]);
  const [dateTo, setDateTo] = useState<Dayjs | null>(dayjs);
  const [dateFrom, setDateFrom] = useState<Dayjs | null>(dayjs);
  const [newUsername, setNewUsername] = useState('');
  const [newUserPassword, setNewUserPassword] = useState('');
  const [newUserFirstname, setNewUserFirstname] = useState('');
  const [newUserLasttname, setNewUserLastname] = useState('');
  const [newUserEmail, setNewUserEmail] = useState('');

  const columnsUsers: GridColDef[] = [
    {
      field: "user",
      headerName: "User",
      width: 150,
      renderCell: (params) => params.row.username,
    },
    {
      field: "role",
      headerName: "Роль",
      width: 150,
      renderCell: (params) => params.row.role,
    },
    {
      field: "registration_date",
      headerName: "Дата регистарции",
      width: 100,
      renderCell: (params) =>
        new Date(
          Date.parse(params.row["registration_date"])
        ).toLocaleDateString("ru-ru"),
    },
    {
      field: "ban_date",
      headerName: "Дата бана",
      width: 100,
      renderCell: (params) =>
        new Date(Date.parse(params.row["ban_date"])).toLocaleDateString(
          "ru-ru"
        ),
    },
    {
      field: "ban",
      headerName: "Забанить",
      width: 100,
      renderCell: (params) => (
        <Button onClick={() => banUser(params.row["id"])}>Забанить</Button>
      ),
    },
  ];

  const columns: GridColDef[] = [
    {
      field: "user",
      headerName: "User",
      width: 150,
      renderCell: (params) => params.row.user.username,
    },
    {
      field: "date",
      headerName: "Date",
      width: 100,
      renderCell: (params) =>
        new Date(Date.parse(params.row["time"])).toLocaleDateString("ru-ru"),
    },
    {
      field: "time",
      headerName: "Time",
      type: "number",
      width: 110,
      renderCell: (params) =>
        new Date(Date.parse(params.row["time"])).toLocaleTimeString("ru-ru"),
    },
    {
      field: "age",
      headerName: "Age",
      description: "This column has a value getter and is not sortable.",
      sortable: false,
      width: 110,
      renderCell: (params) =>
        params &&
        params.row["params"].map((item: any) =>
          item.name === "{AGE}" ? item.value : ""
        ),
    },
    {
      field: "task type",
      headerName: "Task Type",
      description: "This column has a value getter and is not sortable.",
      sortable: false,
      width: 110,
      renderCell: (params) =>
        params.row["params"].map((item: any) =>
          item.name === "{TASK TYPE}" ? item.value : ""
        ),
    },
    {
      field: "level",
      headerName: "Level",
      description: "This column has a value getter and is not sortable.",
      sortable: false,
      width: 110,
      renderCell: (params) =>
        params.row["params"].map((item: any) =>
          item.name === "{LEVEL}" ? item.value : ""
        ),
    },
    {
      field: "ASPECT",
      headerName: "Aspect",
      description: "This column has a value getter and is not sortable.",
      sortable: false,
      width: 110,
      renderCell: (params) =>
        params.row["params"].map((item: any) =>
          item.name === "{ASPECT}" ? item.value : ""
        ),
    },
    {
      field: "WORDS",
      headerName: "Words",
      description: "This column has a value getter and is not sortable.",
      sortable: false,
      width: 110,
      renderCell: (params) =>
        params.row["params"].map((item: any) =>
          item.name === "{WORDS}" ? item.value : ""
        ),
    },
    {
      field: "comment",
      headerName: "Текст",
      sortable: false,
      width: 250
    },
    {
      field: "request_action",
      headerName: "Действие",
      sortable: false,
      renderCell: (params) => 
        params.row["request_action"].slice(-1)[0] && params.row["request_action"].slice(-1)[0]["action"]
        
    }
  ];

  const History = () => {
    /*
    useEffect(() => {
      getHistory()
    }, [showHistory], 
    )
    */

    return (
      <div className="history">
        <DataGrid
          rows={history}
          columns={columns}
          getRowHeight={(params) => "auto"}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 9,
              },
            },
          }}
          pageSizeOptions={[5]}
          disableRowSelectionOnClick
        />
        <div className="dates">
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            Дата от:&nbsp;&nbsp;  
            <DatePicker 
            format="DD.MM.YYYY"
            value={dateFrom}
            onChange={(newValue) => setDateFrom(newValue)} 
            />
          </LocalizationProvider>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            &nbsp;&nbsp;Дата до:&nbsp;&nbsp;  
            <DatePicker
             format="DD.MM.YYYY"
             value={dateTo}
             onChange={(newValue) => setDateTo(newValue)} 
             />
          </LocalizationProvider>
        </div>
      </div>
    );
  };

  const Users = () => {
    return (
      <div className="users">
        <DataGrid
          rows={users}
          columns={columnsUsers}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 9,
              },
            },
          }}
          pageSizeOptions={[5]}
          disableRowSelectionOnClick
        />
        <div className="dates">
          <Button onClick={() => handleOpenAddUser()}>Добавить пользователя</Button>
        </div>
      </div>
    );
  };

  const handleChangeMultiple = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const { options } = event.target;
    const value: string[] = [];
    
    for (let i = 0, l = options.length; i < l; i += 1) {
      if (options[i].selected) {
        setCurrentParamName(options[i].text);
        value.push(options[i].value);
        for (let x = 0, l = params.length; x < l; x += 1) {
          for (let y = 0, l = params[x].param_value.length; y < l; y += 1) {
            if (params[x].param_value[y].id === Number(options[i].value)) {
              setCurrentParamValue(params[x].param_value[y].value);
            }
          }
        }
        let updatedValue: ISelectedParams = {
          param_id: Number(event.target.getAttribute("id")),
          selected_value_id: Number(value),
        };
        const index = selectedParams.findIndex(
          (param) => param.param_id === updatedValue.param_id
        );
        const updatedSelectedParams = [...selectedParams];
        updatedSelectedParams[index] = {
          ...updatedSelectedParams[index],
          selected_value_id: updatedValue.selected_value_id,
        };
        if (index >= 0) {
          let counter = updatedSelectedParams.length;
          while (counter !== index + 1) {
            updatedSelectedParams.pop();
            counter--;
          }
          setSelectedParams(updatedSelectedParams);
        } else {
          setSelectedParams((prevState) => [...prevState, updatedValue]);
        }
      }
    }
    setPersonName(value);
    setCurrentParamValueId(Number(value));
  };

  const handleChangeMultipleStatic = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const { options } = event.target;
    const value: string[] = [];
    for (let i = 0, l = options.length; i < l; i += 1) {
      console.log('test2')
      if (options[i].selected) {
        setCurrentParamName(options[i].text);
        setCurrentParamValue(options[i].value)
      }
    }
    setPersonName(value);
    setCurrentParamValueId(Number(value));
  };

  const handleChangePromt = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const { options } = event.target;
    const value: string[] = [];
    for (let i = 0, l = options.length; i < l; i += 1) {
      if (options[i].selected) {
        setCurrentParamName(options[i].text);
        value.push(options[i].value);
        setCurrentParamValue(promtText);
      }
    }
    setPersonName(value);
  };

  const handlePromtTextChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setCurrentParamValue(event.target.value);
  };

  const getUsers = async () => {
    try {
      const res = await fetch(Endpoints.USER.GET_USERS, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${access}`,
        },
      });
      const data = await res.json();
      setUsers(data);
    } catch (e: any) {
      console.log(e);
    }
  };

  const banUser = async (id: number) => {
    try {
      const res = await fetch(Endpoints.USER.BAN_USER + id, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${access}`,
        },
      });
      const data = await res.json();
      setUsers(data.results);
    } catch (e: any) {
      console.log(e);
    }
  };

  const getParams = async (id: number) => {
    try {
      const res = await fetch(Endpoints.PROMT.GET_PARAMS + "/" + id, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${access}`,
        },
      });
      if (res.status === 200) {
        const data = await res.json();
        console.log(data);
        let updatedValue: IParam = data;
        setParams((prevState) => [...prevState, updatedValue]);
      } else {
        console.log(res);
      }
    } catch (e: any) {
      console.log(e);
    }
  };

  const getStaticParams = async (id: number) => {
    try {
      const res = await fetch(Endpoints.PROMT.GET_PARAMS + "/" + id, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${access}`,
        },
      });
      if (res.status === 200) {
        const data = await res.json();
        console.log(data);
        let updatedValue: IParam = data;
        setStaticParams((prevState) => [...prevState, updatedValue]);
      } else {
        console.log(res);
      }
    } catch (e: any) {
      console.log(e);
    }
  };

  const getChildrenParams = async (selectedParams: ISelectedParams[]) => {
    try {
      const data = selectedParams;
      const res = await fetch(Endpoints.PROMT.GET_PARAMS, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${access}`,
        },
        body: JSON.stringify(data),
      });

      if (res.status === 200) {
        const data = await res.json();
        setParams(data);
      } else {
        console.log(res);
      }
    } catch (e: any) {
      console.log(e);
    }
  };

  const addParam = async (id: number, name: string, value: string) => {
    try {
      const data = {
        name: name,
        value: value,
        param: id,
        head_param_value: currentHeadParamValue,
      };
      const res = await fetch(Endpoints.PROMT.ADD_PARAM, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${access}`,
        },
        body: JSON.stringify(data),
      });
      if (res.status > 199 && res.status < 300) {
        handleCloseAddParam();
        console.log(data);
      } else {
        console.log(res);
      }
    } catch (e: any) {
      console.log(e);
    }
  };

  const deleteParam = async (id: number) => {
    try {
      const res = await fetch(Endpoints.PROMT.DELETE_PARAM + id, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${access}`,
        },
      });
      if (res.status > 199 && res.status < 300) {
        handleCloseDeleteParam();
      } else {
        console.log(res);
      }
    } catch (e: any) {
      console.log(e);
    }
  };

  const editParam = async (id: number, value: string) => {
    try {
      const data = {
        name: currentParamName,
        value: value,
      };
      const res = await fetch(Endpoints.PROMT.EDIT_PARAM + id, {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${access}`,
        },
        body: JSON.stringify(data),
      });
      if (res.status > 199 && res.status < 300) {
        setEditedStatus(true);
      } else {
        console.log(res);
      }
    } catch (e: any) {
      console.log(e);
    }
  };

  const getPromt = async () => {
    try {
      const res = await fetch(Endpoints.PROMT.GET_PROMT, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${access}`,
        },
      });
      const data = await res.json();
      setPromtText(data.text);
      if (res.status === 200) {
        console.log(data);
      } else {
        console.log(res);
      }
    } catch (e: any) {
      console.log(e);
    }
  };

  const editPromt = async (value: string) => {
    try {
      const data = {
        text: value,
      };
      const res = await fetch(Endpoints.PROMT.EDIT_PROMT, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${access}`,
        },
        body: JSON.stringify(data),
      });
      if (res.status > 199 && res.status < 300) {
      } else {
        console.log(res);
      }
    } catch (e: any) {
      console.log(e);
    }
  };

  const getCurrentUser = async () => {
    try {
      const res = await fetch(Endpoints.USER.GET_CURRENT_USER, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${access}`,
        },
      });
      const data = await res.json();
      setFirstName(data.first_name);
      setLastName(data.last_name);
      setEmail(data.email);
      if (res.status === 200) {
        console.log(data);
      } else {
        console.log(res);
      }
    } catch (e: any) {
      console.log(e);
    }
  };

  const getHistory = async () => {
    try {
      const res = await fetch(Endpoints.PROMT.GET_HISTORY + '?start=' +  new Date(Date.parse(String(dateFrom))).toLocaleDateString(
        "ru-ru") 
        + '&end=' + new Date(Date.parse(String(dateTo))).toLocaleDateString(
          "ru-ru"), {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${access}`,
        },
      });
      const data = await res.json();
      if (res.status === 200) {
        setHistory(data);
      } else {
        console.log(res);
      }
    } catch (e: any) {
      console.log(e);
    }
  };

  const addUser = async (username: string, password: string, first_name: string, last_name: string, email_address: string) => {
    try {
      const data = {
        username: username,
        password: password,
        first_name: first_name,
        last_name: last_name,
        email_address: email_address
      };
      const res = await fetch(Endpoints.USER.ADD_USER, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${access}`,
        },
        body: JSON.stringify(data),
      });
      if (res.status > 199 && res.status < 300) {
        handleCloseAddPUser();
        console.log(data);
      } else {
        console.log(res);
      }
    } catch (e: any) {
      console.log(e);
    }
  }

  useEffect(() => {
    getStaticParams(2);
    getStaticParams(4);
  }, []);

  useEffect(() => {
    params.length === 0 ? getParams(1) : getChildrenParams(selectedParams);
    getPromt();
    getCurrentUser();
    setEditedStatus(false);
    getHistory();
    getUsers();
    console.log(params);
  }, [
    openDeleteParam,
    openAddParam,
    openAddUser,
    editedStatus,
    showHistory,
    selectedParams,
    showUsers,
    dateFrom,
    dateTo
  ]);

  return (
    <ThemeProvider theme={theme}>
      <Container className="customContainer">
        <Tabs defaultIndex={0} onSelect={(index) => setCurrentTab(index)}>
          <div className="sidebar">
            <div className="profile">
              <div className="photo"></div>
              <div className="profileName">
                {firstName} {lastName}
              </div>
              <div className="profileMail">{email}</div>
            </div>
            <TabList>
              <div className="menu">
                <div className="sidebarHeader">Меню</div>
                <div className="menuOption">Preparing for class</div>
              </div>
              <div className="admin">
                <div className="sidebarHeader">Админ</div>
                <div
                  className="menuOption usersOption"
                  onClick={() => {
                    setShowHistory(false);
                    setShowUsers(true);
                    var element = document.getElementsByClassName(
                      "react-tabs__tab--selected"
                    );
                    element.length > 0
                      ? (element[0].className = "react-tabs__tab")
                      : console.log("");
                    var usersOption =
                      document.getElementsByClassName("usersOption");
                    usersOption[0].className =
                      "menuOption usersOption selected";
                    var history =
                      document.getElementsByClassName("statisticOption");
                    history[0].className = "menuOption statisticOption";
                  }}
                >
                  Управление пользователями
                </div>
              </div>
              <div className="promtsMenu">
                <div className="sidebarHeader">Правка промтов</div>
                <Tab>
                  <div
                    className="menuOption"
                    onClick={() => {
                      setShowHistory(false);
                      setShowUsers(false);
                      var history =
                        document.getElementsByClassName("statisticOption");
                      history[0].className = "menuOption statisticOption";
                      var usersOption =
                        document.getElementsByClassName("usersOption");
                      usersOption[0].className = "menuOption usersOption";
                    }}
                  >
                    Меню1
                  </div>
                </Tab>
                <Tab>
                  <div
                    className="menuOption"
                    onClick={() => {
                      setShowHistory(false);
                      setShowUsers(false);
                      var history =
                        document.getElementsByClassName("statisticOption");
                      history[0].className = "menuOption statisticOption";
                      var usersOption =
                        document.getElementsByClassName("usersOption");
                      usersOption[0].className = "menuOption usersOption";
                    }}
                  >
                    Меню2
                  </div>
                </Tab>
                <Tab>
                  <div
                    className="menuOption"
                    onClick={() => {
                      setShowHistory(false);
                      setShowUsers(false);
                      var history =
                        document.getElementsByClassName("statisticOption");
                      history[0].className = "menuOption statisticOption";
                      var usersOption =
                        document.getElementsByClassName("usersOption");
                      usersOption[0].className = "menuOption usersOption";
                    }}
                  >
                    Меню3
                  </div>
                </Tab>
              </div>
              <div className="statistics">
                <div className="sidebarHeader">Статистика</div>
                <div
                  className="menuOption statisticOption"
                  onClick={() => {
                    var element = document.getElementsByClassName(
                      "react-tabs__tab--selected"
                    );
                    element.length > 0
                      ? (element[0].className = "react-tabs__tab")
                      : console.log("");
                    var history =
                      document.getElementsByClassName("statisticOption");
                    history[0].className =
                      "menuOption statisticOption selected";
                    setShowHistory(true);
                  }}
                >
                  Запросы
                </div>
              </div>
            </TabList>
            <div className="baseLinks">
              <div>FAQ</div>
              <div>Настройки</div>
              <div onClick={() => handleLogOut()}>Выйти</div>
            </div>
          </div>
          <div className="tabContent">
            {showHistory ? (
              <History />
            ) : showUsers ? (
              <Users />
            ) : (
              <div className="promts">
                <div className="upper tabContent-block">
                  <div className="upperHeader">Настройки</div>
                  <TabPanel>
                    <div className="columns">
                      {params?.map(
                        (item) =>
                          item.name !== "{TASK TYPE}" &&
                          item.name !== "{ASPECT}" && (
                            <div className="age">
                              <div className="columnHeader">
                                {item.name === "{AGE}" && "Возраст"}
                                {item.name === "{LEVEL}" && "Программа"}
                                {item.name === "{GRAMMAR}" && "Grammar"}
                                {item.name === "{WORDS}" && "Vocabulary"}
                                {item.name === "{UNIT}" && "Unit"}
                              </div>
                              <div className="options">
                                <FormControl
                                  className="customSelect"
                                  sx={{ m: 1, minWidth: 116, maxWidth: 186 }}
                                >
                                  <Select<string[]>
                                    multiple
                                    native
                                    value={personName}
                                    // @ts-ignore Typings are not considering `native`
                                    onChange={handleChangeMultiple}
                                    inputProps={{
                                      id: String(item.id),
                                    }}
                                  >
                                    {item.param_value.map((param_value) => (
                                      <option
                                        key={param_value.value}
                                        value={param_value.id}
                                      >
                                        {param_value.name}
                                      </option>
                                    ))}
                                  </Select>
                                  <div className="buttons">
                                    <Button
                                      onClick={() => {
                                        setCurrentParamId(item.id);
                                        setCurrentHeadParamValue(
                                          item.param_value[0].head_param_value
                                        );
                                        handleOpenAddParam();
                                      }}
                                    >
                                      <AddOutlinedIcon />
                                    </Button>

                                    <Button
                                      onClick={() => handleOpenDeleteParam()}
                                    >
                                      <DeleteOutlineOutlinedIcon />
                                    </Button>
                                  </div>
                                </FormControl>
                              </div>
                            </div>
                          )
                      )}
                    </div>
                  </TabPanel>
                  <TabPanel>
                    <div className="columns">
                      {staticParams?.map(
                        (item) =>
                          item.name === "{ASPECT}" && (
                            <div className="age">
                              <div className="columnHeader">Этап урока</div>
                              <div className="options">
                                <FormControl
                                  className="customSelect"
                                  sx={{ m: 1, minWidth: 276, maxWidth: 276 }}
                                >
                                  <Select<string[]>
                                    multiple
                                    native
                                    value={personName}
                                    // @ts-ignore Typings are not considering `native`
                                    onChange={handleChangeMultipleStatic}
                                    inputProps={{
                                      id: String(item.param_value[0]),
                                    }}
                                  >
                                    {item.param_value.map((param_value) => (
                                      <option
                                        key={param_value.value}
                                        value={param_value.value}
                                      >
                                        {param_value.name}
                                      </option>
                                    ))}
                                  </Select>
                                  <div className="buttons">
                                    <Button
                                      onClick={() => {
                                        setCurrentParamId(item.id);
                                        handleOpenAddParam();
                                      }}
                                    >
                                      <AddOutlinedIcon />
                                    </Button>
                                    <Button
                                      onClick={() => handleOpenDeleteParam()}
                                    >
                                      <DeleteOutlineOutlinedIcon />
                                    </Button>
                                  </div>
                                </FormControl>
                              </div>
                            </div>
                          )
                      )}

                      {staticParams?.map(
                        (item) =>
                          item.name === "{TASK TYPE}" && (
                            <div className="age">
                              <div className="columnHeader">Отработка</div>
                              <div className="options">
                                <FormControl
                                  className="customSelect"
                                  sx={{ m: 1, minWidth: 276, maxWidth: 276 }}
                                >
                                  <Select<string[]>
                                    multiple
                                    native
                                    value={personName}
                                    // @ts-ignore Typings are not considering `native`
                                    onChange={handleChangeMultipleStatic}
                                    inputProps={{
                                      id: String(item.id),
                                    }}
                                  >
                                    {item.param_value.map((param_value) => (
                                      <option
                                        key={param_value.value}
                                        value={param_value.value}
                                      >
                                        {param_value.name}
                                      </option>
                                    ))}
                                  </Select>
                                  <div className="buttons">
                                    <Button
                                      onClick={() => {
                                        setCurrentParamId(item.id);
                                        setCurrentHeadParamValue(
                                          item.param_value[0].head_param_value
                                        );
                                        handleOpenAddParam();
                                      }}
                                    >
                                      <AddOutlinedIcon />
                                    </Button>
                                    <Button
                                      onClick={() => handleOpenDeleteParam()}
                                    >
                                      <DeleteOutlineOutlinedIcon />
                                    </Button>
                                  </div>
                                </FormControl>
                              </div>
                            </div>
                          )
                      )}
                    </div>
                  </TabPanel>
                  <TabPanel>
                    <div className="columns">
                      <div className="age">
                        <div className="columnHeader">Основной промт</div>
                        <div className="options">
                          <FormControl
                            className="customSelect"
                            sx={{ m: 1, minWidth: 271, maxWidth: 271 }}
                          >
                            <Select<string[]>
                              multiple
                              native
                              value={personName}
                              // @ts-ignore Typings are not considering `native`
                              onChange={handleChangePromt}
                              inputProps={{
                                id: "select-multiple-native",
                              }}
                            >
                              {names.map((name) => (
                                <option key={name} value={promtText}>
                                  {name}
                                </option>
                              ))}
                            </Select>
                            <div className="buttons">
                              <AddOutlinedIcon />
                              <DeleteOutlineOutlinedIcon />
                            </div>
                          </FormControl>
                        </div>
                      </div>
                    </div>
                  </TabPanel>
                </div>
                <div className="bottom tabContent-block">
                  <div className="promtHeader">
                    {currentParamName === "" ? (
                      "Название параметра"
                    ) : (
                      <TextField
                        value={currentParamName}
                        className="customTextField"
                        onChange={(event) =>
                          setCurrentParamName(event.target.value)
                        }
                        rows={1}
                      />
                    )}
                  </div>
                  <div className="promtTextField">
                    <TextField
                      className="customTextField"
                      id="outlined-basic"
                      label=""
                      variant="outlined"
                      multiline
                      value={currentParamValue}
                      onChange={(event) => handlePromtTextChange(event)}
                      inputProps={{ maxLength: 2000 }}
                      rows={3}
                    />
                  </div>
                  <Button
                    onClick={() => {
                      if (currentTab === 2) {
                        editPromt(currentParamValue);
                      } else {
                        editParam(currentParamValueId, currentParamValue);
                      }
                    }}
                    variant="contained"
                    type={"submit"}
                    color="primary"
                  >
                    Сохранить
                  </Button>
                </div>
              </div>
            )}
          </div>
        </Tabs>
      </Container>

      <Modal
        open={openAddParam}
        onClose={handleCloseAddParam}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="modalCustom">
          <div className="modalHeader">Добавление параметра</div>
          <TextField
            className="customTextField paramName"
            id="outlined-basic"
            label="Название параметра"
            variant="outlined"
            multiline
            rows={1}
            onChange={(e) => setParamName(e.target.value)}
          />
          <TextField
            className="customTextField patternText"
            id="outlined-basic"
            label="Текст шаблона для отправки в chatgpt"
            variant="outlined"
            multiline
            rows={4}
            inputProps={{ maxLength: 2000 }}
            onChange={(e) => setParamValue(e.target.value)}
          />
          <div className="modal-buttons">
            <Button
              className="cancelButton"
              onClick={() => handleCloseAddParam()}
            >
              Отменить
            </Button>
            <Button
              onClick={() => addParam(currentParamId, paramName, paramValue)}
              variant="contained"
            >
              Экспортировать
            </Button>
          </div>
        </Box>
      </Modal>

      <Modal
        open={openDeleteParam}
        onClose={handleCloseDeleteParam}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="modalCustom deleteCustom">
          <div className="modalHeader">Удаление параметра</div>
          <div className="modalTextDelete">
            Вы действительно хотите удалить данный параметр?
          </div>
          <div className="modal-buttons">
            <Button
              className="cancelButton"
              onClick={() => handleCloseDeleteParam()}
            >
              Нет
            </Button>
            <Button
              variant="contained"
              onClick={() => deleteParam(currentParamValueId)}
            >
              Да
            </Button>
          </div>
        </Box>
      </Modal>

      <Modal
        open={openAddUser}
        onClose={handleCloseAddPUser}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="modalCustom addUser">
          <div className="modalHeader">Добавление пользователя</div>
          <TextField
            className="customTextField paramName"
            id="outlined-basic"
            label="Username"
            variant="outlined"
            multiline
            rows={1}
            onChange={(e) => setNewUsername(e.target.value)}
          />  
          <TextField
            className="customTextField paramName"
            id="outlined-basic"
            label="Пароль"
            variant="outlined"
            multiline
            rows={1}
            onChange={(e) => setNewUserPassword(e.target.value)}
          />  
          <TextField
            className="customTextField paramName"
            id="outlined-basic"
            label="Имя"
            variant="outlined"
            multiline
            rows={1}
            onChange={(e) => setNewUserFirstname(e.target.value)}
          />  
          <TextField
            className="customTextField paramName"
            id="outlined-basic"
            label="Фамилия"
            variant="outlined"
            multiline
            rows={1}
            onChange={(e) => setNewUserPassword(e.target.value)}
          />  
          <TextField
            className="customTextField paramName"
            id="outlined-basic"
            label="email"
            variant="outlined"
            multiline
            rows={1}
            onChange={(e) => setNewUserEmail(e.target.value)}
          />  
          <div className="modal-buttons">
            <Button
              className="cancelButton"
              onClick={() => handleCloseAddPUser()}
            >
              Отменить
            </Button>
            <Button
              onClick={() => addUser(newUsername, newUserPassword, newUserFirstname, newUserLasttname, newUserEmail)}
              variant="contained"
            >
              Добавить
            </Button>
          </div>
        </Box>
      </Modal>

    </ThemeProvider>
  );
};

export default Admin;
