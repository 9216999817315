import {RootState} from "../store";

export const saveState = (state: RootState) => {
    try {
        const serializedState = JSON.stringify(state.user.access);
        localStorage.setItem('reduxState', removeQuotes(serializedState));
    } catch (error) {
        console.log('Ошибка при сохранении состояния в Local Storage:', error);
    }
};

export const removeQuotes = (str: string) => {
    return str.replace(/["']/g, '');
}