import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import "./Client.css";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { getToken } from "../../store/selectors";
import { Typography, Container } from "@mui/material";
import { logoutUser } from "../../store/userSlice";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import PrintIcon from "@mui/icons-material/Print";
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import { Endpoints } from "../../api/endpoints";
import { Button, TextField, FormControl, InputLabel } from "@mui/material";

const theme = createTheme({
  palette: {
    primary: {
      main: "#A02130",
    },
  },
});

interface IParam {
  name: string;
  param_value: [{ id: number; name: string; value: string }];
  id: number;
}

interface IParamSent {
  param_id: Number;
  value_id: Number;
}

interface IParamValues {
  id: Number;
  name: String;
  value: String;
}

interface ISelectedParams {
  param_id: Number;
  selected_value_id: Number;
}

const Client = () => {
  let navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [age, setAge] = useState("");
  const { access } = useAppSelector(getToken);
  const [params, setParams] = useState<IParam[]>([]);
  const [promtText, setPromtText] = useState("");
  const [promtResult, setPromResult] = useState("");
  const [paramValues, setParamValues] = useState<IParamSent[]>([]);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [levelParams, setLevelParams] = useState<IParamValues[]>([]);
  const [unitParams, setUnitParams] = useState<IParamValues[]>([]);
  const [vocabularyParams, setVocabularyParams] = useState<IParamValues[]>([]);
  const [grammarParams, setGrammarParams] = useState<IParamValues[]>([]);
  const [selectedParams, setSelectedParams] = useState<ISelectedParams[]>([]);
  const [currentParamId, setCurrentParamId] = useState(0);
  const [currentValueId, setCurrentValueId] = useState(0);
  const [sessionId, setSessionId] = useState('');


  const handleChange = (event: SelectChangeEvent, param_id: number) => {
    let updatedValue = {
      param_id: param_id,
      value_id: Number(event.target.value),
    };
    getChildrenParams(param_id, Number(event.target.value));
    setCurrentParamId(param_id);
    setCurrentValueId(Number(event.target.value))
    setParamValues((prevState) => [...prevState, updatedValue]);
  };

  const handleChangeAge = (event: SelectChangeEvent, param_id: number) => {
    let updatedValue = {
      param_id: param_id,
      value_id: Number(event.target.value),
    };
    getChildrenParams(param_id, Number(event.target.value));
    setCurrentParamId(param_id)
    setCurrentValueId(Number(event.target.value))
    setParamValues((prevState) => [...prevState, updatedValue]);
  };



  const handleLogOut = () => {
    dispatch(logoutUser());
    localStorage.clear();
    navigate("/login");
  };

  const getParams = async () => {
    try {
      const res = await fetch(Endpoints.PROMT.GET_PARAMS, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${access}`,
        },
      });
      const data = await res.json();
      setParams(data);
      if (res.status === 200) {
        console.log(data);
      } else {
        console.log(res);
      }
    } catch (e: any) {
      console.log(e);
    }
  };

  const createPromt = async () => {
    try {
      const data = {
        param_data: paramValues,
        comment: promtText,
      };
      const res = await fetch(Endpoints.PROMT.CREATE_PROMT, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${access}`,
        },
        body: JSON.stringify(data),
      });
      if (res.status === 200) {
        let x = await res.json();
        setPromResult(x.result);
        setSessionId(x.session)
        console.log(res);
      } else {
        console.log(res);
      }
    } catch (e: any) {
      console.log(e);
    }
  };

  const addAction = async (action: string) => {
    try {
      console.log(sessionId);
      const data = {
        session: sessionId,
        action: action,
      };
      const res = await fetch(Endpoints.PROMT.ADD_ACTION, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${access}`,
        },
        body: JSON.stringify(data),
      });
      if (res.status === 200) {
        let x = await res.json();
        setPromResult(x.result);
        console.log(res);
      } else {
        console.log(res);
      }
    } catch (e: any) {
      console.log(e);
    }
  };


  const getCurrentUser = async () => {
    try {
      const res = await fetch(Endpoints.USER.GET_CURRENT_USER, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${access}`,
        },
      });
      const data = await res.json();
      setFirstName(data.first_name);
      setLastName(data.last_name);
      setEmail(data.email);
      if (res.status === 200) {
        console.log(data);
      } else {
        console.log(res);
      }
    } catch (e: any) {
      console.log(e);
    }
  };

  const getChildrenParams = async (id: Number, value_id: Number) => {
    let updatedValue: ISelectedParams = {
      param_id: id,
      selected_value_id: value_id
    }
    const index = selectedParams.findIndex((param) => param.param_id === updatedValue.param_id);
    const updatedSelectedParams = [...selectedParams];
    updatedSelectedParams[index] = { ...updatedSelectedParams[index], selected_value_id: updatedValue.selected_value_id}
    if (index >= 0) {
      let counter = updatedSelectedParams.length
      while (counter !== index+1){
        updatedSelectedParams.pop();
        counter--;
      }
      setSelectedParams(updatedSelectedParams)
    }
    else {
      console.log('test')
      setSelectedParams((prevState) => [...prevState, updatedValue]) 
      
    }
    try {
      const res = await fetch(Endpoints.PROMT.GET_PARAMS, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${access}`,
        },
        body: JSON.stringify(selectedParams),
      });

      if (res.status === 200) {
        const data = await res.json();
        data.map((item:any) => (
          item.head_param_name === "{AGE}" ? setLevelParams(item.param_value) : null,
          item.head_param_name === "{LEVEL}" ? setUnitParams(item.param_value) : null,
          (item.head_param_name === "{UNIT}" && item.name ==="{WORDS}") ? setVocabularyParams(item.param_value) : null,
          (item.head_param_name === "{UNIT}" && item.name ==="{GRAMMAR}") ? setGrammarParams(item.param_value) : null
        )
      )
        
      } else {
        console.log(res);
      }
    } catch (e: any) {
      console.log(e);
    }
  };

  useEffect(() => {
    getParams();
    getCurrentUser();
    getChildrenParams(currentParamId, currentValueId);
  }, [paramValues]);

  return (
    <ThemeProvider theme={theme}>
      <Container className="customContainer">
        <div className="sidebar">
          <div className="profile">
            <div className="photo"></div>
            <div className="profileName">
              {firstName} {lastName}
            </div>
            <div className="profileMail">{email}</div>
          </div>
          <div className="menu">
            <div className="menuOption">Preparing for class</div>
          </div>

          <div className="baseLinks">
            <div>FAQ</div>
            <div>Настройки</div>
            <div className="logout" onClick={() => handleLogOut()}>
              Выйти
            </div>
          </div>
        </div>
        <div className="tabContent">
          <div className="promts">
            <div className="upper tabContent-block">
              <div className="upperHeader">Настройки</div>
              <div className="columns">
                {params.map((item) =>
                  item.name === "{AGE}" ? (
                    <div className="column-content">
                      <FormControl fullWidth>
                        <InputLabel id={"demo-simple-select-label"}>
                          Возраст
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          key={item.id}
                          defaultValue={"0"}
                          label="Возраст"
                          onChange={(event) => handleChangeAge(event, item.id)}
                        >
                          {item.name === "{AGE}" &&
                            item.param_value.map((item) => (
                              <MenuItem value={item.id}>{item.name}</MenuItem>
                            ))}
                        </Select>
                      </FormControl>
                    </div>
                  ) : null
                )}

                {params.map((item) =>
                  item.name === "{LEVEL}" ? (
                    <div className="column-content">
                      <FormControl fullWidth>
                        <InputLabel id={"level"}>
                          Программа
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          key={item.id}
                          defaultValue={"0"}
                          label="Возраст"
                          onChange={(event) => handleChangeAge(event, item.id)}
                        >
                          {item.name === "{LEVEL}" &&
                            levelParams.map((item: any) => (
                              <MenuItem value={item.id}>{item.name}</MenuItem>
                            ))}
                        </Select>
                      </FormControl>
                    </div>
                  ) : null
                )}

                {params.map((item) =>
                  item.name === "{UNIT}" ? (
                    <div className="column-content">
                      <FormControl fullWidth>
                        <InputLabel id={"unit"}>
                          {item.name === "{UNIT}" ? "Unit" : "Grammar"}
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          key={item.id}
                          defaultValue={"0"}
                          label="Возраст"
                          onChange={(event) => handleChangeAge(event, item.id)}
                        >
                          {unitParams.map((item: any) => (
                            <MenuItem value={item.id}>{item.name}</MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </div>
                  ) : null
                )}

                {params.map((item) =>
                  item.name === "{GRAMMAR}" ? (
                    <div className="column-content">
                      <FormControl fullWidth>
                        <InputLabel id={"demo-simple-select-label"}>
                          {item.name === "{GRAMMAR}" ? "Grammar" : "Grammar"}
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          key={item.id}
                          defaultValue={"0"}
                          label="Возраст"
                          onChange={(event) => handleChange(event, item.id)}
                        >
                          {grammarParams.map((item: any) => (
                            <MenuItem value={item.id}>{item.name}</MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </div>
                  ) : null
                )}

                {params.map((item) =>
                  item.name === "{WORDS}" ? (
                    <div className="column-content">
                      <FormControl fullWidth>
                        <InputLabel id={"demo-simple-select-label"}>
                          {item.name === "{WORDS}" ? "Words" : "Vocabulary"}
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          key={item.id}
                          defaultValue={"0"}
                          label="Возраст"
                          onChange={(event) => handleChange(event, item.id)}
                        >
                          {vocabularyParams.map((item: any) => (
                            <MenuItem value={item.id}>{item.name}</MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </div>
                  ) : null
                )}
              </div>
              <div className="columns">
                {params.map((item) =>
                  item.name === "{TASK TYPE}" || item.name === "{ASPECT}" ? (
                    <div className="column-content">
                      <FormControl fullWidth>
                        <InputLabel id={"demo-simple-select-label"}>
                          {item.name === "{TASK TYPE}"
                            ? "Этап урока"
                            : "Тип обработки"}
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          key={item.id}
                          defaultValue={"0"}
                          label="Возраст"
                          onChange={(event) => handleChange(event, item.id)}
                        >
                          {item.param_value.map((item) => (
                            <MenuItem value={item.id}>{item.name}</MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </div>
                  ) : null
                )}
              </div>
              <div className="promtTextField">
                <TextField
                  className="customTextField"
                  id="outlined-basic"
                  label="Введите сюда комментарий"
                  variant="outlined"
                  multiline
                  value={promtText}
                  onChange={(e) => setPromtText(e.target.value)}
                  rows={1}
                  inputProps={{ maxLength: 2000 }}
                />
              </div>
              <Button
                onClick={() => createPromt()}
                variant="contained"
                type={"submit"}
                color="primary"
              >
                <AutoAwesomeIcon />
                &nbsp; Сгенерировать
              </Button>
            </div>
            <div className="bottom tabContent-block">
              <div className="bottom-header">
                <div className="promtHeader">Результат</div>
              </div>
              <div className="promtTextField">
                <TextField
                  className="customTextField"
                  id="outlined-basic"
                  label=""
                  value={promtResult}
                  variant="outlined"
                  multiline
                  disabled
                  rows={10}
                  inputProps={{ maxLength: 2000 }}
                />
              </div>
              <div className="bottom-utils">
                  <div
                    className="copuToBuffer"
                    onClick={() => 
                      {
                        navigator.clipboard.writeText(promtResult);
                        addAction('Copy');
                      }
                    }
                  >
                    <ContentCopyIcon />
                    &nbsp;Копировать
                  </div>
                  <div
                    className="sentToPrint"
                    onClick={() => {
                      window.print();
                      addAction('Print');
                    }}
                  >
                    <PrintIcon />
                    &nbsp;Распечатать
                  </div>
                </div>
            </div>
          </div>
        </div>
      </Container>
    </ThemeProvider>
  );
};

export default Client;
