import {useAppSelector} from "../../store/hooks";
import {getToken} from "../../store/selectors";

export const useAuth = () => {
   const {access} = useAppSelector(getToken);

   return {
      isAuth: !!access
   }
}
