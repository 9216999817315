import { useNavigate } from "react-router-dom";
import { useState } from "react";
import "./Login.css";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import {loginUser} from "../../store/userSlice";
import { getToken } from "../../store/selectors";
import { Endpoints } from "../../api/endpoints";
import { Typography } from "@mui/material";
import {
  Button,
  TextField,
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  IconButton,
} from "@mui/material";

const theme = createTheme({
  palette: {
    primary: {
      main: "#A02130",
    },
  },
});

const Login = () => {
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  const dispatch = useAppDispatch();
  const { access } = useAppSelector(getToken);
  const navigate = useNavigate();
  const savedToken = localStorage.getItem("access");

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const handleSubmit = async () => {
    const data = {
      username: username,
      password: password,
    };

    try {
      const res = await fetch(Endpoints.AUTH.LOGIN, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });
      const token = await res.json();

      if (res.status === 200) {
        dispatch(loginUser(token.access));
        
        token.role === 'ADMIN' ? navigate("/admin") : navigate('/client');
      } else {
        console.log(res);
      }
    } catch (e: any) {
      console.log(e);
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <div className="authForm">
        <div className="formHeader">Вход в систему</div>
        <FormControl>
          <TextField placeholder="Логин" onChange={(e) => setUsername(e.target.value)}/>
          <FormControl>
            <InputLabel htmlFor="outlined-adornment-password">
              Пароль
            </InputLabel>
            <OutlinedInput
              onChange={(e) => setPassword(e.target.value)}
              id="outlined-adornment-password"
              type={showPassword ? "text" : "password"}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
              label="Password"
            />
          </FormControl>
          <Button variant="contained" onClick={handleSubmit} color="primary">
            Войти
          </Button>
        </FormControl>
      </div>
    </ThemeProvider>
  );
};

export default Login;
